import html2canvas from 'html2canvas'
import Vue from 'vue'
import common from '../common/common.js'
import i18n from '../common/i18n'
export default {
	getUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
		})
	},
	isAuth(key) {
		return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
	},
	REG_EN() {
		return /^[\a-zA-Z0-9]+$/
	},
	// 纯英文
	REG_PURE_EN() {
		return /^[\a-zA-Z]+$/
	},
	REG_CH() {
		return /^[\u4e00-\u9fa5]+$/; // 中文
	},
	REG_INTEGER() { //整数
		return /^[1-9]+[0-9]*$/
	},
	REG_ZERO_INTEGER() { //包括0的整数
		return /^[0-9]+[0-9]*$/
	},
	REG_TEL() { //电话
		return /^\d+$/
	},
	REG_NUMBER() { //数字
		return /^[1-9]+[0-9]*(\.\d{0,4})?$/;
	},
	AMOUNT(){
		return /^[1-9]+[0-9]*(\.\d{0,2})?$/;
	},
	REG_EMAIL() { //验证邮箱
		return /^([a-zA-Z0-9]+[_|\_|\.\-]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
	},
	REG_PHONE() { //手机号格式
		return /^1[3|4|5|6|7|8|9]\d{9}$/
	},
	REG_EMAIL_PHONE() { //手机邮箱
		return /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|6|7|8|9]\d{9}$)/;
	},
	REG_URL() { //验证url
		return /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/;
	},
	REG_NEW_PASSWORK() { //6-20位数字字母组合成的密码
		return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
	},
	// 验证身份证号
	VerifyIDNumber(){
		return /[1-9]\d{5}(((1[89]|20)\d{2}(((0[13578]|1[0-2])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8]))))|((((1[89]|20)(0[48]|[2468][048]|[13579][26]))|((19|20)00))0229))\d{3}(\d|X|x)/
	},
	toDecimal2(x) { //强制保留两位小数
		var f = parseFloat(x);
		if (isNaN(f)) {
			return false;
		}
		var f = Math.round(x * 100) / 100;
		var s = f.toString();
		var rs = s.indexOf('.');
		if (rs < 0) {
			rs = s.length;
			s += '.';
		}
		while (s.length <= rs + 2) {
			s += '0';
		}
		return s;
	},
	toDecimal4(x) { //强制保留四位小数
		var f = parseFloat(x);
		if (isNaN(f)) {
			return false;
		}
		var f = Math.round(x * 10000) / 10000;
		var s = f.toString();
		var rs = s.indexOf('.');
		if (rs < 0) {
			rs = s.length;
			s += '.';
		}
		while (s.length <= rs + 4) {
			s += '0';
		}
		return s;
	},
	// vue转成图片下载
	toImage(item) {
		let imageWrapper = item
		var cliewidth = imageWrapper.offsetWidth
		var clieheight = imageWrapper.offsetHeight
		var kh = [cliewidth, clieheight]

		html2canvas(imageWrapper).then(canvas => {
			let dataURL = canvas.toDataURL("image/png");
			new Promise(resolve => {
				let canvas = document.createElement('canvas')
				let ctx = canvas.getContext('2d')
				let img = new Image()
				img.crossOrigin = 'Anonymous' //允许跨域
				img.src = dataURL
				img.onload = function() {
					canvas.height = kh[1]
					canvas.width = kh[0]
					ctx.drawImage(img, 0, 0, kh[0], kh[1])
					let dataURL = canvas.toDataURL('image/png')
					canvas = null
					resolve(dataURL)
				}
			}).then(base64 => {
				let link = document.createElement('a')
				link.href = base64
				link.download = 'Voucher.png'
				link.click()
			})
		});
	},
	headup() {
		let upData = {
			'satoken': localStorage.getItem('satoken')
		}
		return upData
	},
	IsitEmpty(item){//判断是否为空
		if(item==false||item==null||item==undefined||item.length==0||item==''||JSON.stringify(item)=='{}'){
			return false
		}else{
			return true
		}		
	},
	IsitSpace(item){//判断字符串是否包含空格
		var judgeFn = new RegExp(/\s+/g);
		if (judgeFn.test(item)) {
			// console.log("【结果】：内容包含有空格!");
			return false
		}else{
			return true
		}	
	},
	ThousandBitSymbol(num) { //转化千位符
	    var source = String(parseFloat(num).toFixed(2)).split("."); //按小数点分成2部分
	    source[0] = source[0].replace( new RegExp('(\\d)(?=(\\d{3})+$)' , 'ig'), "$1,");//只将整数部分进行都好分割
	    return source.join("." );//再将小数部分合并进来
	},
	// 贸易类型
	commerceTypeFun(){
		let Arr=[
			{label:i18n.t('enterprise.Tradeingoods'),value:'GOODS',icon:'icon-huowu'},
			{label:i18n.t('enterprise.servicetrade'),value:'SERVICES',icon:'icon-fuwuguwen'}
		]
		return Arr
	},
	formatSeconds(value){
		var secondTime = parseInt(value); // 秒
		var minuteTime = 0; // 分
		var hourTime = 0; // 小时
		if (secondTime > 60) {
		        minuteTime = parseInt(secondTime / 60);
		        secondTime = parseInt(secondTime % 60);
		        if (minuteTime > 60) {
		            hourTime = parseInt(minuteTime / 60);
		            minuteTime = parseInt(minuteTime % 60);
		        }
		}
		let RetLet=this.zeroFill(hourTime) + ':'+ this.zeroFill(minuteTime) + ':' + this.zeroFill(secondTime)
		return RetLet;
	},
	zeroFill(num) {
	    if (!isFinite(num)) {
	        return '';
	    }
	    return num < 10 ? '0' + num : num;
	},
	isTimeInRange(startT,endT) {
		 var nowDate=new Date();
		  var beginDate=new Date(nowDate);
		  var endDate=new Date(nowDate);
		  
		  var beginIndex=startT.lastIndexOf("\:");
		  var beginHour=startT.substring(0,beginIndex);
		  var beginMinue=startT.substring(beginIndex+1,startT.length);
		  beginDate.setHours(beginHour,beginMinue, 0, 0);
		  
		  var endIndex=endT.lastIndexOf("\:");
		  var endHour=endT.substring(0,endIndex);
		  var endMinue=endT.substring(endIndex+1,endT.length);
		  endDate.setHours(endHour, endMinue, 0, 0);
		  return nowDate.getTime()-beginDate.getTime()>=0 && nowDate.getTime() <= endDate.getTime()          
	},
	matchType(fileName){
		// 后缀获取
		var suffix = '';
		// 获取类型结果
		var result = '';
		try {
			var flieArr = fileName.split('.');
			suffix = flieArr[flieArr.length - 1];
		} catch (err) {
			suffix = '';
		}
		// fileName无后缀返回 false
		if (!suffix) {
			result = false;
			return result;
		}
		// 图片格式
		var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
		// 进行图片匹配
		result = imglist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'image';
			return result;
		};
		// 匹配txt
		var txtlist = ['txt'];
		result = txtlist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'txt';
			return result;
		};
		// 匹配 excel
		var excelist = ['xls', 'xlsx'];
		result = excelist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'excel';
			return result;
		};
		// 匹配 word
		var wordlist = ['doc', 'docx'];
		result = wordlist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'word';
			return result;
		};
		// 匹配 pdf
		var pdflist = ['pdf'];
		result = pdflist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'pdf';
			return result;
		};
		// 匹配 ppt
		var pptlist = ['ppt'];
		result = pptlist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'ppt';
			return result;
		};
		// 匹配 视频
		var videolist = ['mp4', 'm2v', 'mkv'];
		result = videolist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'video';
			return result;
		};
		// 匹配 音频
		var radiolist = ['mp3', 'wav', 'wmv'];
		result = radiolist.some(function(item) {
			return item == suffix.toLowerCase();
		});
		if (result) {
			result = 'radio';
			return result;
		}
		// 其他 文件类型
		result = 'other';
		return result;
	},
	createFilter(queryString) {
	    return (restaurant) => {
	      return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) >=0);
	    };    
	},
	scrollToTop() {
		const scrollEle = document.querySelector('.module-bg')
		  scrollEle.scrollTo({
		     top: 0,
		     behavior: 'smooth'
		   });
	   // window.scrollTo({
	   //    top: 0,
	   //    behavior: "smooth"
	   //  });
	},
}

export const REG_EN = /^[\a-zA-Z0-9]+$/;
export const REG_CH = /^[\u4e00-\u9fa5]+$/; // 中文

//整数
export const REG_INTEGER = /^[1-9]+[0-9]*$/;
//数字
export const REG_NUMBER = /^[1-9]+[0-9]*(\.\d{0,4})?$/;
//十进制
export const REG_DECIMAL = /^0(\.\d{0,4})?$/;
//验证邮箱
export const REG_EMAIL =
	/^([a-zA-Z0-9]+[_|\_|\.\-]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.\-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
//手机号格式
export const REG_PHONE = /^1[3|4|5|6|7|8|9]\d{9}$/;
//手机邮箱
export const REG_EMAIL_PHONE = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|6|7|8|9]\d{9}$)/;


export const REG_URL = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:\/~\+#]*[\w\-\@?^=%&\/~\+#])?$/;
export const REG_ADDR = /^[\w\-\@?^=%&\/~\+#]+[\w,`\-\.\@?^=%&\/~\+#\s]*$/;

export const REG_CH_NAME = /^[\u4e00-\u9fa5·-]+$/;
export const REG_EN_NAME = /^[a-zA-Z]*$/;
export const REG_ID = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
export const REG_CARD_ID = /^\d{16,19}$/;

export const REG_EN_UPP = /^[A-Z]*$/;

export const REG_DOMAIN_ROOT = /^(?:[a-zA-Z0-9]+(?:\-*[a-zA-Z0-9])*\.)+[a-zA-Z0-9]{2,63}$/;
//密码
export const REG_PASSWORK = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
//6-20位数字字母组合成的密码
export const REG_NEW_PASSWORK = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/


import CryptoJS from 'crypto-js'
// const key = CryptoJS.enc.Utf8.parse("734e6b616e732b4976735a726377315a"); //十六位十六进制数作为密钥

const KEY = CryptoJS.enc.Utf8.parse(';hw5Q9B*g&C}r-vC');
const IV = CryptoJS.enc.Utf8.parse('1234567890abcdef');
//解密方法
export function decrypt(word, keyStr, ivStr) {
	let key = KEY;
	let iv = IV;

	if (keyStr) {
		key = CryptoJS.enc.Utf8.parse(keyStr);
		iv = CryptoJS.enc.Utf8.parse(ivStr);
	}

	let base64 = CryptoJS.enc.Base64.parse(word);
	let src = CryptoJS.enc.Base64.stringify(base64);

	var decrypt = CryptoJS.AES.decrypt(src, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.ZeroPadding
	});

	var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
}
//加密
export function encrypt(word, keyStr, ivStr) {
	let key = KEY;
	let iv = IV;

	if (keyStr) {
		key = CryptoJS.enc.Utf8.parse(keyStr);
		iv = CryptoJS.enc.Utf8.parse(ivStr);
	}

	let srcs = CryptoJS.enc.Utf8.parse(word);
	var encrypted = CryptoJS.AES.encrypt(srcs, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.ZeroPadding
	});
	// console.log("-=-=-=-", encrypted.ciphertext)
	return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}


/**
 * 获取uuid
 */
export function getUUID() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
		return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
	})
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
	return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

export function IsPC() {
	var userAgentInfo = navigator.userAgent;
	var Agents = ["Android", "iPhone",
	"SymbianOS", "Windows Phone",
	"iPad", "iPod"];
	var flagPc = true;
	for (var v = 0; v < Agents.length; v++) {
		if (userAgentInfo.indexOf(Agents[v]) > 0) {
			flagPc = false;
			break;
		}
	}
	return flagPc;
}
